/* eslint-disable import/no-restricted-paths */
import { GlobalLoader } from 'global-loader';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { routes } from 'shared/routes';
import AssignHealthCoachDrawer from 'health-coaches/assign-health-coach-drawer';
import { FormattedMessage } from 'react-intl';
import { PrivateRoute } from './private-route';

const Login = React.lazy(async () => import('./pages/auth/login'));
const LegacyConsultations = React.lazy(
  async () => import('./doctors/pages/consultations'),
);
const Consultations = React.lazy(
  async () => import('./doctors/pages/consultations/index-tabs'),
);
const Patient = React.lazy(async () => import('./pages/patient'));
const Patients = React.lazy(
  async () => import('./health-coaches/pages/patients'),
);
const Inbox = React.lazy(async () => import('./health-coaches/pages/inbox'));
const Outreach = React.lazy(
  async () => import('./health-coaches/pages/outreach'),
);
const WhoAreYou = React.lazy(async () => import('./pages/who-are-you'));
const Home = React.lazy(async () => import('./pages/home'));

export const Navigation = (): React.ReactElement => {
  const featureFlagClient = useFeatureFlagClient();
  const consultationTabs = featureFlagClient.getBoolean(
    'ff-prac-ui-consultation-tabs',
  );

  return (
    <BrowserRouter>
      <AssignHealthCoachDrawer>
        <React.Suspense fallback={<GlobalLoader />}>
          <Switch>
            <Route path={routes.login}>
              <Login />
            </Route>
            <Route path={routes.whoAreYou}>
              <WhoAreYou />
            </Route>
            <PrivateRoute
              exact
              path={routes.consultations}
              allowedRoles={['DOCTOR']}
            >
              {consultationTabs ? <Consultations /> : <LegacyConsultations />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`${routes.patients}/:patientId`}
              allowedRoles={['DOCTOR', 'HEALTH_COACH']}
            >
              <Patient />
            </PrivateRoute>
            <PrivateRoute
              path={`${routes.patients}`}
              allowedRoles={['HEALTH_COACH']}
            >
              <Patients />
            </PrivateRoute>
            <PrivateRoute
              path={`${routes.inbox}/:coach/:category/:subcategory`}
              allowedRoles={['HEALTH_COACH']}
            >
              <Inbox />
            </PrivateRoute>
            <PrivateRoute
              path={routes.outreach}
              allowedRoles={['HEALTH_COACH']}
            >
              <Outreach />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/"
              allowedRoles={['DOCTOR', 'HEALTH_COACH']}
            >
              <Home />
            </PrivateRoute>
            <Route>
              <FormattedMessage defaultMessage="404" />
            </Route>
          </Switch>
        </React.Suspense>
      </AssignHealthCoachDrawer>
    </BrowserRouter>
  );
};
