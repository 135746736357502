import React, { useMemo, useState, useCallback } from 'react';
import jwtDecode from 'jwt-decode';
import { ApolloClient, gql, HttpLink, InMemoryCache } from '@apollo/client';
import { env } from 'env';
import type {
  DoctorLoginSsoMutation,
  DoctorLoginSsoMutationVariables,
  DoctorImpersonationLoginSsoMutation,
  DoctorImpersonationLoginSsoMutationVariables,
  UserRole,
} from 'graphql/types';
import generatedIntrospection from 'graphql/possible-types';
import { GoogleLogin } from '@react-oauth/google';
import { useSharedTabStorage } from 'shared/hooks/use-shared-tab-storage';
import { useIntl } from 'react-intl';
import { setContext } from '@apollo/client/link/context';
import { useNotifications } from './shared/components/notifications';
import { setRumUser, clearRumUser } from './shared/real-user-monitoring';
import type { Identity } from './providers/identity';

const authKey = `${env.brand}_auth_info`;

export function tokenExpiresAtMs(token: string): number {
  const decodedToken = jwtDecode<{ [key: string]: number } | undefined>(token);
  if (!decodedToken) {
    return 0;
  }

  return (decodedToken.exp ?? 0) * 1000;
}

export function tokenNeedsRefreshAtMs(token: string): number {
  const decodedToken = jwtDecode<{ [key: string]: number } | undefined>(token);
  if (!decodedToken) {
    return 0;
  }

  return ((decodedToken.exp ?? 0) - 300) * 1000;
}

interface Auth {
  loading: boolean;
  loggedIn: boolean;
  isDoctor: boolean;
  role?: UserRole | null;
  userId?: string | null;
  identity: Identity | null;
  token: string | null;
  login: (idToken: string) => Promise<void>;
  impersonate: (idToken: string, email: string) => Promise<void>;
  showAuthModal: () => void;
  logout: () => Promise<void>;
  setIdentity: (identity: Identity) => void;
}

const AuthContext = React.createContext<Auth>({
  loading: true,
  loggedIn: false,
  isDoctor: false,
  token: null,
  userId: null,
  identity: null,
  role: null,
  showAuthModal: () => {
    throw new Error('AuthProvider is required');
  },
  login: async (): Promise<void> => {
    throw new Error('AuthProvider is required');
  },
  impersonate: async (): Promise<void> => {
    throw new Error('AuthProvider is required');
  },
  logout: async (): Promise<void> => {
    throw new Error('AuthProvider is required');
  },
  setIdentity: () => {
    throw new Error('AuthProvider is required');
  },
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
  }),
  link: setContext((_, { headers }) => ({
    headers: {
      ...headers,
      brand: env.brand,
    },
  })).concat(
    new HttpLink({
      uri: `${env.apiUrl}/graphql`,
    }),
  ),
});

export const useAuth = (): Auth => React.useContext(AuthContext);

export const AuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { formatMessage } = useIntl();
  const showNotifications = useNotifications();
  const [showingAuthModal, setShowingAuthModal] = useState(false);
  const [role, setRole] = useState<UserRole | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [identity, setIdentity] = useState<Identity | null>(null);

  const {
    value: token,
    setValue: setToken,
    clearValue: clearToken,
    loading: tokenLoading,
  } = useSharedTabStorage(authKey);

  const showAuthModal = useCallback(() => {
    setShowingAuthModal(true);
  }, []);

  React.useEffect(() => {
    if (token && tokenNeedsRefreshAtMs(token) > Date.now()) {
      setShowingAuthModal(false);
    }

    if (token) {
      const decoded = jwtDecode<{ role?: UserRole; userId: string }>(token);
      setRole(decoded.role ?? null);
      setUserId(decoded.userId);
      setRumUser(decoded.userId, decoded.role);
    }
  }, [token]);

  const login = React.useCallback(
    async (idToken: string): Promise<void> => {
      try {
        const resp = await apolloClient.mutate<
          DoctorLoginSsoMutation,
          DoctorLoginSsoMutationVariables
        >({
          mutation: gql`
            mutation DoctorLoginSSO($idToken: String!) {
              doctorsLoginSSO(id_token: $idToken) {
                token
              }
            }
          `,
          variables: { idToken },
        });

        const tokenFromLogin = resp.data?.doctorsLoginSSO.token;
        if (tokenFromLogin) {
          setToken(tokenFromLogin);
        }
      } catch (e) {
        showNotifications({
          type: 'error',
          message: formatMessage({ defaultMessage: 'Login failed' }),
        });
      }
    },
    [setToken, showNotifications, formatMessage],
  );

  const impersonate = React.useCallback(
    async (idToken: string, impersonateEmail: string): Promise<void> => {
      try {
        const resp = await apolloClient.mutate<
          DoctorImpersonationLoginSsoMutation,
          DoctorImpersonationLoginSsoMutationVariables
        >({
          mutation: gql`
            mutation DoctorImpersonationLoginSSO(
              $idToken: String!
              $impersonateEmail: String!
            ) {
              doctorsImpersonationLoginSSO(
                id_token: $idToken
                impersonateEmail: $impersonateEmail
              ) {
                token
              }
            }
          `,
          variables: { idToken, impersonateEmail },
        });

        const tokenFromLogin = resp.data?.doctorsImpersonationLoginSSO.token;
        if (tokenFromLogin) {
          setToken(tokenFromLogin);
        }
      } catch (e) {
        showNotifications({
          type: 'error',
          message: formatMessage({ defaultMessage: 'Impersonation failed' }),
        });
      }
    },
    [setToken, showNotifications, formatMessage],
  );

  const logout = React.useCallback(async (): Promise<void> => {
    clearToken();
    setRole(null);
    setUserId(null);
    setIdentity(null);
    clearRumUser();
  }, [clearToken]);

  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({
          loading: tokenLoading,
          showAuthModal,
          loggedIn: !!token,
          role,
          isDoctor: role === 'DOCTOR',
          token,
          login,
          impersonate,
          logout,
          userId,
          identity,
          setIdentity,
        }),
        [
          tokenLoading,
          role,
          showAuthModal,
          token,
          login,
          identity,
          setIdentity,
          impersonate,
          logout,
          userId,
        ],
      )}
    >
      {tokenLoading ? undefined : children}
      <div className="hidden">
        {(!token || showingAuthModal) && (
          <GoogleLogin
            onSuccess={async ({ credential: idToken }): Promise<void> => {
              if (idToken) {
                if (env.environment !== 'production') {
                  if (
                    jwtDecode<{ email: string }>(idToken).email.endsWith(
                      '@eucalyptus.vc',
                    )
                  ) {
                    if (!identity?.email) {
                      throw new Error('missing email to impersonate');
                    }

                    await impersonate(idToken, identity.email);
                    return;
                  }
                }

                await login(idToken);
              } else {
                showNotifications({
                  message: formatMessage({
                    defaultMessage: 'Unable to login.',
                  }),
                  type: 'error',
                });
              }
            }}
            onError={(): void => {
              showNotifications({
                message: formatMessage({
                  defaultMessage: 'Unable to login.',
                }),
                type: 'error',
              });
            }}
            useOneTap
            auto_select
            cancel_on_tap_outside={false}
          />
        )}
      </div>
    </AuthContext.Provider>
  );
};
