import { gql, useQuery } from '@apollo/client';
import { useAuth } from 'auth';
import type {
  AssignDrawerDataQuery,
  AssignDrawerDataQueryVariables,
  ChatThreadStatus,
} from 'graphql/types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useChangeUrl } from 'shared/hooks/use-change-url';
import { AssignDrawer } from './assign-drawer';

const QUERY = gql`
  query AssignDrawerData {
    profile {
      id
    }
    chatThreadStatusCountsByType(type: HEALTH_COACH) {
      id
      assigned {
        id
        user {
          id
          email
          fullName
          isAvailable
          avatar {
            id
            url
          }
        }
        counts {
          id
          count
          status
        }
      }
      unassigned {
        id
        count
        status
      }
      all {
        id
        count
        status
      }
    }
    healthCoaches {
      id
      email
      fullName
      isAvailable
      avatar {
        id
        url
      }
    }
  }
`;

const AssignHealthCoachDrawer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { search } = useLocation();
  const { role } = useAuth();
  const changeUrl = useChangeUrl();
  const showDrawer =
    new URLSearchParams(search).get('showAssignDrawer') === 'true';

  const { data } = useQuery<
    AssignDrawerDataQuery,
    AssignDrawerDataQueryVariables
  >(QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: role !== 'HEALTH_COACH',
  });

  const threadCountsByUserId = React.useMemo(() => {
    const res =
      data?.chatThreadStatusCountsByType?.assigned?.reduce<
        Record<
          string,
          {
            fullName: string | null | undefined;
            avatarImageUrl?: string;
            isAvailable: boolean;
            counts: Record<ChatThreadStatus, number>;
          }
        >
      >((acc, curr) => {
        if (!curr.user?.id) {
          return acc;
        }
        return {
          ...acc,
          [curr.user.id]: {
            fullName: curr.user.fullName,
            avatarImageUrl: curr.user.avatar?.url,
            isAvailable: curr.user.isAvailable ?? false,
            counts: {
              TO_ACTION:
                curr.counts?.find((c) => c.status === 'TO_ACTION')?.count ?? 0,
              AWAITING_PATIENT:
                curr.counts?.find((c) => c.status === 'AWAITING_PATIENT')
                  ?.count ?? 0,
              NO_ACTION_REQUIRED:
                curr.counts?.find((c) => c.status === 'NO_ACTION_REQUIRED')
                  ?.count ?? 0,
              CLOSED:
                curr.counts?.find((c) => c.status === 'CLOSED')?.count ?? 0,
              SCHEDULED_MESSAGE:
                curr.counts?.find((c) => c.status === 'SCHEDULED_MESSAGE')
                  ?.count ?? 0,
            },
          },
        };
      }, {}) ?? {};

    data?.healthCoaches?.forEach((hc) => {
      if (!(hc.id in res)) {
        res[hc.id] = {
          fullName: hc.fullName,
          avatarImageUrl: hc.avatar?.url,
          isAvailable: hc.isAvailable ?? false,
          counts: {
            TO_ACTION: 0,
            AWAITING_PATIENT: 0,
            NO_ACTION_REQUIRED: 0,
            CLOSED: 0,
            SCHEDULED_MESSAGE: 0,
          },
        };
      }
    });

    return res;
  }, [data?.chatThreadStatusCountsByType?.assigned, data?.healthCoaches]);

  const defaultUnassignedThreadCounts: Record<ChatThreadStatus, number> = {
    AWAITING_PATIENT: 0,
    CLOSED: 0,
    NO_ACTION_REQUIRED: 0,
    TO_ACTION: 0,
    SCHEDULED_MESSAGE: 0,
  };
  const unassignedThreadCounts =
    data?.chatThreadStatusCountsByType?.unassigned?.reduce<
      Record<ChatThreadStatus, number>
    >((acc, curr) => {
      if (!curr.status) {
        return acc;
      }
      return {
        ...acc,
        [curr.status]: curr.count,
      };
    }, defaultUnassignedThreadCounts);

  if (role !== 'HEALTH_COACH') {
    return <>{children}</>;
  }

  return (
    <>
      <AssignDrawer
        show={showDrawer}
        onClose={(): void => {
          changeUrl({
            params: {
              showAssignDrawer: undefined,
            },
          });
        }}
        threadCountsByUserId={threadCountsByUserId}
        unassignedThreadCounts={
          unassignedThreadCounts ?? defaultUnassignedThreadCounts
        }
      />
      {children}
    </>
  );
};

export default AssignHealthCoachDrawer;
