
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ChatThreadParticipant": [
      "CustomerChatThreadParticipant",
      "HealthCoachChatThreadParticipant"
    ],
    "ConsultationPrescriberAction": [
      "ConsultationPrescriberContinueExistingSequenceAction",
      "ConsultationPrescriberDismissAction",
      "ConsultationPrescriberPrescribeInitialSequenceAction",
      "ConsultationPrescriberPrescribeNewSequenceAction",
      "ConsultationPrescriberRejectAction",
      "ConsultationPrescriberReprescribeExistingSequenceAction"
    ],
    "ContentItem": [
      "FaqContentItem",
      "ReadingContentItem",
      "RecipeContentItem",
      "VideoContentItem",
      "VideoWithReadingContentItem"
    ],
    "CreateWearableIntegrationPayload": [
      "CreateNativeWearableIntegrationPayload",
      "CreateWebApiWearableIntegrationPayload"
    ],
    "HealthCoachingFlowNode": [
      "HealthCoachingFlowMessageNode",
      "HealthCoachingFlowWaitNode"
    ],
    "HealthCoachingFlowOrTemplate": [
      "HealthCoachingFlow",
      "HealthCoachingFlowTemplate"
    ],
    "HeartRateVariabilitySample": [
      "RMSSDHeartRateVariabilitySample",
      "SDNNHeartRateVariabilitySample"
    ],
    "LevelTreeGoalTemplate": [
      "DefaultLevelTreeGoalTemplate",
      "VariantLevelTreeGoalTemplate"
    ],
    "MediaAttachment": [
      "EngineChatMediaAttachment",
      "QuizMediaAttachment",
      "StreamChatMediaAttachment"
    ],
    "MobileAppFeature": [
      "HealthCoachChat"
    ],
    "Note": [
      "ConsultationNote",
      "CustomerNote",
      "MetaNote"
    ],
    "Offering": [
      "AddOnOffering",
      "OtcOffering",
      "RxOffering"
    ],
    "PaginationPayload": [
      "ConsultationTab",
      "CustomerSearchPayload",
      "FilteredChatThreadsPayload",
      "FilteredConsultationsPayload"
    ],
    "PhoneCallProviderDetails": [
      "LinePhoneCallDetails",
      "ZoomPhoneCallDetails"
    ],
    "PrescribeDisclaimer": [
      "GeneralPrescribeDisclaimer"
    ],
    "PurchaseAction": [
      "PurchaseResumeAction"
    ],
    "PurchasePrompt": [
      "ConfirmPurchasePrompt",
      "FurPurchasePrompt",
      "InitialPurchasePrompt",
      "RestartFlowPrompt",
      "SubstitutePurchasePrompt"
    ],
    "QuizOutcomeV2": [
      "ArticleQuizOutcome",
      "BuyOtcQuizOutcome",
      "ConsultationQuizOutcome",
      "IncreaseUsageQuizOutcome",
      "NotEligibleQuizOutcome",
      "OtcQuizOutcome",
      "ReviewQuizOutcome",
      "SideEffectProactiveActionQuizOutcome",
      "ZendeskQuizOutcome"
    ],
    "QuizOutcomeV2Result": [
      "ArticleQuizOutcome",
      "BuyOtcQuizOutcome",
      "ConsultationQuizOutcome",
      "IncreaseUsageQuizOutcome",
      "NotEligibleQuizOutcome",
      "OtcQuizOutcome",
      "ReviewQuizOutcome",
      "SideEffectProactiveActionQuizOutcome",
      "ZendeskQuizOutcome"
    ],
    "Sequence": [
      "OtcSequence",
      "PrescribableSequence"
    ],
    "SequenceNode": [
      "EventSequenceNode",
      "InternalTriggerSequenceNode",
      "SmsSequenceNode",
      "VariantSequenceNode",
      "WaitSequenceNode"
    ]
  }
};
      export default result;
    