import React from 'react';
import { useHomeRoute, routes } from 'shared/routes';
import { env } from 'env';
import { useHistory, useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useIdentity } from 'providers/identity';
import { FormattedMessage } from 'react-intl';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { useAuth } from './auth';
import { Button } from './shared/components/button';
import { UserAvatar } from './shared/components/avatar';

export function NavBarLayout(props: {
  children: React.ReactNode;
}): React.ReactElement | null {
  const location = useLocation();
  const history = useHistory();
  const { logout, role, loading, userId } = useAuth();
  const identity = useIdentity();
  const homeRoute = useHomeRoute({ role });
  const featureFlagClient = useFeatureFlagClient();

  const navRoutes = [homeRoute];
  if (role === 'HEALTH_COACH') {
    if (featureFlagClient.getBoolean('coaching_inbox_v2')) {
      navRoutes.push({
        path: `${routes.inbox}/${userId}/to-do/new-messages`,
        title: 'Inbox',
      });
    } else {
      navRoutes.push({
        path: routes.patients,
        title: 'Inbox',
        search: `?primaryUserIds=${userId}&chatThreadStatuses=TO_ACTION`,
      });
    }
    navRoutes.push({
      path: routes.outreach,
      title: 'Outreach',
      search: '?orderBy=PRIORITY_DESC&context=outreach',
    });
  }

  const handleLogout = React.useCallback(() => {
    void logout();
    history.push(routes.login);
  }, [logout, history]);

  if (loading || role === undefined) {
    return null;
  }

  return (
    <div>
      <header className="fixed h-16 z-40 bg-white shadow-md w-full">
        <div className="h-full px-5 flex items-center justify-between">
          <div className="flex">
            <div className="ml-3 flex items-center">
              <Link to={homeRoute.path + (homeRoute.search ?? '')}>
                <img className="ml-3 h-8 flex-1" src={env.logoUrl} />
              </Link>
            </div>
            <ul className="flex ml-12 gap-3">
              {navRoutes.map((route) => (
                <Link
                  to={route.path + (route.search ?? '')}
                  key={route.path + (route.search ?? '')}
                >
                  <li
                    className={classNames(
                      'flex',
                      'items-center',
                      'm-3',
                      'capitalize',
                      'text-slate-800',
                      'hover:text-slate-900',
                      {
                        'text-primary': location.pathname.includes(route.title),
                      },
                    )}
                  >
                    {route.title}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="flex items-center justify-center">
            <div className="mr-4 flex-1">
              {identity && <UserAvatar user={identity} />}
            </div>
            <div className="mr-3">
              <Button variant="text" onClick={handleLogout}>
                <FormattedMessage defaultMessage="Logout" />
              </Button>
            </div>
          </div>
        </div>
      </header>
      <div className="pt-16 h-screen">{props.children}</div>
    </div>
  );
}
