import type React from 'react';
import type { Styles } from 'react-select';
import Select from 'react-select';
import type { Control, RegisterOptions } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Label } from 'shared/components/label';
import { InputError } from 'shared/components/input-error';
import type { TooltipProps } from './tool-tip';

export interface Option<T = string> {
  value?: T;
  label: string | JSX.Element;
  disabled?: boolean;
}

interface DropdownProps<T = string> {
  name: string;
  options: Option<T>[];
  control: Control;
  label?: string;
  disabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  errorMessage?: string | JSX.Element;
  rules?: RegisterOptions;
  isMulti?: boolean;
  defaultValue?: string;
  customStyles?: Styles<Option<string>, boolean>;
  placeholder?: string;
  tooltipProps?: TooltipProps;
  description?: string;
  searchable?: boolean;
  handleSelect?: (val: T | T[] | undefined) => void;
}

export const Dropdown = ({
  name,
  options,
  control,
  label,
  isMulti = false,
  disabled = false,
  menuPlacement = 'auto',
  errorMessage,
  rules,
  customStyles,
  defaultValue,
  placeholder,
  handleSelect,
  description,
  tooltipProps,
  searchable,
}: DropdownProps): JSX.Element => (
  <div>
    {(label || description) && (
      <div className="mb-3 flex flex-col gap-1">
        {label && (
          <Label htmlFor={name} tooltip={tooltipProps ?? undefined}>
            {label}
          </Label>
        )}
        {description && <p className="text-sm text-slate-700">{description}</p>}
      </div>
    )}
    <Controller
      defaultValue=""
      // eslint-disable-next-line @typescript-eslint/no-shadow
      render={({ onChange, onBlur, value, name, ref }) => (
        <Select
          isMulti={isMulti}
          inputId={name}
          options={options}
          isDisabled={disabled}
          className="dropdown"
          classNamePrefix="dd"
          placeholder={placeholder}
          menuPlacement={menuPlacement}
          menuPortalTarget={document.body}
          isOptionDisabled={(option) => !!option.disabled}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (base): React.CSSProperties => ({
              ...base,
              borderColor: errorMessage ? '#f56565' : '#CBD5E1',
              borderRadius: '4px',
            }),
            ...customStyles,
            indicatorSeparator: (base): React.CSSProperties => ({
              ...base,
              marginTop: 4,
              marginBottom: 4,
              backgroundColor: '#CBD5E1',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: '#94A3B8',
            }),
          }}
          innerRef={ref}
          onBlur={onBlur}
          defaultInputValue={defaultValue}
          value={
            isMulti && Array.isArray(value)
              ? options.filter((it) => value.includes(it.value))
              : options.find((it) => it.value === value) ?? null
          }
          onChange={(v) => {
            // Multi-select dropdowns
            if (isMulti) {
              if (Array.isArray(v) && v.every((option) => 'value' in option)) {
                const vals = v.map((option) => option.value);
                onChange(vals);
                if (handleSelect) {
                  handleSelect(vals);
                }
              } else if (v === null) {
                onChange([]);
                if (handleSelect) {
                  handleSelect([]);
                }
              }
            }

            // Single-select dropdowns
            if (v && 'value' in v) {
              onChange(v.value);
              if (handleSelect) {
                handleSelect(v.value);
              }
            }
          }}
        />
      )}
      name={name}
      control={control}
      rules={rules}
      isSearchable={searchable}
    />
    <InputError>{errorMessage}</InputError>
  </div>
);
