import type { RadioPropsOption } from 'shared/components/radio';
import { Radio } from 'shared/components/radio';
import type React from 'react';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Drawer } from 'shared/components/drawer';
import { Typography } from 'shared/components/ds-components/Typography';
import type {
  AssignChatThreadCountInput,
  AssignChatThreadsMutation,
  AssignChatThreadsMutationVariables,
  AssignMutationWriteQuery,
  AssignMutationWriteQueryVariables,
  ChatThreadStatus,
  ReassignChatThreadsMutation,
  ReassignChatThreadsMutationVariables,
  SetHealthCoachAvailabilityMutation,
  SetHealthCoachAvailabilityMutationVariables,
} from 'graphql/types';
import { Button } from 'shared/components/button';
import { useUpdateEffect } from 'react-use';
import clsx from 'clsx';
import './table-styles.css';
import { Input } from 'shared/components/input';
import { Dropdown } from 'shared/components/dropdown';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useNotifications } from 'shared/components/notifications';
import { Toggle } from 'shared/components/toggle';
import shuffle from 'lodash.shuffle';
import { Avatar } from 'shared/components/avatar';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as WarningDiamond } from '../assets/warning-diamond.svg';
import { CHAT_THREAD_STATUSES, mapThreadStatusToReadableName } from '../utils';
import { useFilteredChatThreadsSearchParams } from '../use-filtered-chat-threads-search-params';

type Action = 'assign' | 'reassign';

const ACTION_OPTIONS: RadioPropsOption<Action>[] = [
  {
    label: 'Assign',
    subLabel: 'Select a primary coach',
    value: 'assign',
    name: 'action',
    defaultChecked: true,
  },
  {
    label: 'Reassign',
    subLabel: 'Change primary coach',
    value: 'reassign',
    name: 'action',
  },
];

const STATUSES_OPTIONS: RadioPropsOption<ChatThreadStatus[]>[] = [
  {
    label: 'To Action',
    value: ['TO_ACTION'],
    name: 'statuses',
    defaultChecked: true,
  },
  {
    label: 'Awaiting Patient + Scheduled Message + No Action Required',
    value: ['AWAITING_PATIENT', 'SCHEDULED_MESSAGE', 'NO_ACTION_REQUIRED'],
    name: 'statuses',
  },
];

type AssignDrawerProps = {
  show: boolean;
  onClose?: () => void;
  threadCountsByUserId: Record<
    string,
    {
      fullName: string | null | undefined;
      avatarImageUrl?: string;
      isAvailable: boolean;
      counts: Record<ChatThreadStatus, number>;
    }
  >;
  unassignedThreadCounts: Record<ChatThreadStatus, number>;
};

const CHAT_THREAD_STATUS_COUNTS_FRAGMENT = gql`
  fragment ChatThreadStatusCountsByType on ChatThreadStatusCounts {
    id
    assigned {
      id
      user {
        id
      }
      counts {
        id
        count
        status
      }
    }
    unassigned {
      id
      count
      status
    }
    all {
      id
      count
      status
    }
  }
`;

const WRITE_QUERY = gql`
  query AssignMutationWrite(
    $threadType: ChatThreadType!
    $filterInput: FilteredChatThreadsInput
  ) {
    filteredChatThreads(input: $filterInput) {
      nextPageToken
      chatThreads {
        id
      }
    }
    chatThreadStatusCountsByType(type: $threadType) {
      ...ChatThreadStatusCountsByType
    }
  }
  ${CHAT_THREAD_STATUS_COUNTS_FRAGMENT}
`;

// We aim to balance assign count equally on the TO_ACTION count
const calculateBalancedAssignSplit = (
  currentCounts: Record<string, { counts: { TO_ACTION: number } }>,
  totalThreadsThatCanBeAssigned: number,
): Record<string, number> => {
  const res = Object.keys(currentCounts).reduce<Record<string, number>>(
    (acc, curr) => ({ ...acc, [curr]: 0 }),
    {},
  );
  if (totalThreadsThatCanBeAssigned <= 0) {
    return res;
  }

  // For each health coach, find their difference to the totalThreadsThatCanBeAssigned
  const userDifferenceToTotal = shuffle(Object.entries(currentCounts)).reduce<
    Record<string, number>
  >(
    (acc, [userId, { counts }]) => ({
      ...acc,
      [userId]: totalThreadsThatCanBeAssigned - counts.TO_ACTION,
    }),
    {},
  );

  // Create an array from 0 -> max difference, where each array value is a set of health coaches that have that difference
  const healthCoachesAtDifference: Set<string>[] = [];
  Object.entries(userDifferenceToTotal).forEach(([userId, difference]) => {
    for (let i = 0; i <= difference; i += 1) {
      if (healthCoachesAtDifference[i]) {
        healthCoachesAtDifference[i]?.add(userId);
      } else {
        const set = new Set<string>();
        set.add(userId);
        healthCoachesAtDifference[i] = set;
      }
    }
  });

  // Traverse through array in reverse the until we've assigned n threads
  let totalThreadsAssigned = 0;
  for (let i = healthCoachesAtDifference.length - 1; i >= 0; i -= 1) {
    if (totalThreadsAssigned >= totalThreadsThatCanBeAssigned) {
      break;
    }
    for (const userId of healthCoachesAtDifference[i]?.values() ?? []) {
      if (totalThreadsAssigned >= totalThreadsThatCanBeAssigned) {
        continue;
      }

      if (res[userId]) {
        res[userId] += 1;
      }
      totalThreadsAssigned += 1;
    }
  }

  return res;
};

export const AssignDrawer = ({
  show,
  onClose,
  threadCountsByUserId,
  unassignedThreadCounts,
}: AssignDrawerProps): React.ReactElement => {
  const apolloClient = useApolloClient();
  const { formatMessage } = useIntl();
  const showNotification = useNotifications();
  const filterInput = useFilteredChatThreadsSearchParams();
  const defaultThreadCounts: Record<string, number> = Object.keys(
    threadCountsByUserId,
  ).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: 0,
    }),
    {},
  );

  const healthCoachAvailabilities = Object.entries(threadCountsByUserId).reduce<
    Record<string, boolean>
  >(
    (acc, [userId, { isAvailable }]) => ({
      ...acc,
      [userId]: isAvailable,
    }),
    {},
  );

  const { register, reset, handleSubmit, watch, control, setValue } = useForm<
    Partial<{
      action: Action;
      statuses: string;
      reassigningFromUserId: string;
      threadCounts: Record<string, number>;
    }>
  >({
    defaultValues: {
      threadCounts: defaultThreadCounts,
    },
  });

  const {
    action,
    statuses: statusesString,
    reassigningFromUserId,
    threadCounts,
  } = watch();
  const statuses = statusesString ? statusesString.split(',') : [];

  const [setHealthCoachAvailability] = useMutation<
    SetHealthCoachAvailabilityMutation,
    SetHealthCoachAvailabilityMutationVariables
  >(gql`
    mutation SetHealthCoachAvailability(
      $userId: ID!
      $makeAvailable: Boolean!
    ) {
      setHealthCoachAvailability(
        userId: $userId
        makeAvailable: $makeAvailable
      ) {
        user {
          id
          isAvailable
        }
      }
    }
  `);

  const [formStep, setFormStep] = useState<'action' | 'assign'>('action');

  useUpdateEffect(() => {
    if (!show) {
      reset();
      setFormStep('action');
    }
  }, [show, reset]);

  const [assignChatThreads, { loading: assignChatThreadsLoading }] =
    useMutation<
      AssignChatThreadsMutation,
      AssignChatThreadsMutationVariables
    >(gql`
      mutation AssignChatThreads(
        $counts: [AssignChatThreadCountInput!]!
        $statuses: [ChatThreadStatus!]!
        $threadType: ChatThreadType!
        $filterInput: FilteredChatThreadsInput
      ) {
        assignChatThreads(
          counts: $counts
          statuses: $statuses
          threadType: $threadType
        ) {
          filteredChatThreads(input: $filterInput) {
            nextPageToken
            chatThreads {
              id
            }
          }
          chatThreadStatusCountsByType(type: $threadType) {
            ...ChatThreadStatusCountsByType
          }
        }
      }
      ${CHAT_THREAD_STATUS_COUNTS_FRAGMENT}
    `);

  const [reassignChatThreads, { loading: reassignChatThreadsLoading }] =
    useMutation<
      ReassignChatThreadsMutation,
      ReassignChatThreadsMutationVariables
    >(gql`
      mutation ReassignChatThreads(
        $fromUserId: ID!
        $counts: [AssignChatThreadCountInput!]!
        $statuses: [ChatThreadStatus!]!
        $threadType: ChatThreadType!
        $filterInput: FilteredChatThreadsInput
      ) {
        reassignChatThreads(
          fromUserId: $fromUserId
          counts: $counts
          statuses: $statuses
          threadType: $threadType
        ) {
          filteredChatThreads(input: $filterInput) {
            nextPageToken
            chatThreads {
              id
            }
          }
          chatThreadStatusCountsByType(type: $threadType) {
            ...ChatThreadStatusCountsByType
          }
        }
      }
      ${CHAT_THREAD_STATUS_COUNTS_FRAGMENT}
    `);

  const onSubmit = handleSubmit(async (values) => {
    const filteredStatuses = values.statuses
      ?.split(',')
      .filter((s): s is ChatThreadStatus =>
        CHAT_THREAD_STATUSES.includes(s as ChatThreadStatus),
      );
    const counts = Object.entries(values.threadCounts ?? {})
      .reduce<AssignChatThreadCountInput[]>(
        (acc, [userId, count]) => [...acc, { userId, count }],
        [],
      )
      .filter(({ count }) => count > 0);

    if (action === 'assign' && filteredStatuses) {
      const { data } = await assignChatThreads({
        variables: {
          filterInput,
          threadType: 'HEALTH_COACH',
          statuses: filteredStatuses,
          counts,
        },
      });
      apolloClient.writeQuery<
        AssignMutationWriteQuery,
        AssignMutationWriteQueryVariables
      >({
        query: WRITE_QUERY,
        variables: {
          filterInput,
          threadType: 'HEALTH_COACH',
        },
        data: {
          filteredChatThreads: data?.assignChatThreads?.filteredChatThreads,
          chatThreadStatusCountsByType:
            data?.assignChatThreads?.chatThreadStatusCountsByType,
        },
      });
    }

    if (action === 'reassign' && reassigningFromUserId && filteredStatuses) {
      const { data } = await reassignChatThreads({
        variables: {
          fromUserId: reassigningFromUserId,
          filterInput,
          threadType: 'HEALTH_COACH',
          statuses: filteredStatuses,
          counts,
        },
      });
      apolloClient.writeQuery<
        AssignMutationWriteQuery,
        AssignMutationWriteQueryVariables
      >({
        query: WRITE_QUERY,
        variables: {
          filterInput,
          threadType: 'HEALTH_COACH',
        },
        data: {
          filteredChatThreads: data?.reassignChatThreads?.filteredChatThreads,
          chatThreadStatusCountsByType:
            data?.reassignChatThreads?.chatThreadStatusCountsByType,
        },
      });
    }

    showNotification({
      message: formatMessage({
        defaultMessage: 'Patients assigned successfully',
      }),
      type: 'success',
    });

    if (onClose) {
      onClose();
    }
  });

  const totalThreadsAssigned = Object.values(threadCounts ?? {}).reduce(
    (acc, curr) => acc + (curr || 0),
    0,
  );

  let currentThreadCounts: Record<ChatThreadStatus, number>;
  if (reassigningFromUserId) {
    currentThreadCounts = threadCountsByUserId[reassigningFromUserId]
      ?.counts ?? {
      CLOSED: 0,
      TO_ACTION: 0,
      AWAITING_PATIENT: 0,
      NO_ACTION_REQUIRED: 0,
      SCHEDULED_MESSAGE: 0,
    };
  } else {
    currentThreadCounts = unassignedThreadCounts;
  }
  const totalThreadsThatCanBeAssigned = Object.entries(
    currentThreadCounts,
  ).reduce<number>((statusAcc, [status, count]) => {
    if (statuses.some((s) => s === status)) {
      return statusAcc + count;
    }
    return statusAcc;
  }, 0);

  const threadsThatCanBeAssignedByStatus = Object.entries(
    currentThreadCounts,
  ).reduce<Record<ChatThreadStatus, number>>(
    (acc, [status, count]) => {
      if (!statuses.some((s) => s === status)) {
        return acc;
      }
      acc[status as ChatThreadStatus] += count;
      return acc;
    },
    {
      AWAITING_PATIENT: 0,
      NO_ACTION_REQUIRED: 0,
      TO_ACTION: 0,
      CLOSED: 0,
      SCHEDULED_MESSAGE: 0,
    },
  );

  const balancedAssignSplit = useMemo(() => {
    const threadCountsInput = {
      ...threadCountsByUserId,
    };
    if (reassigningFromUserId && reassigningFromUserId in threadCountsInput) {
      delete threadCountsInput[reassigningFromUserId];
    }
    Object.entries(threadCountsByUserId).forEach(
      ([userId, { isAvailable }]) => {
        if (!isAvailable) {
          delete threadCountsInput[userId];
        }
      },
    );
    return calculateBalancedAssignSplit(
      threadCountsInput,
      totalThreadsThatCanBeAssigned,
    );
  }, [
    reassigningFromUserId,
    threadCountsByUserId,
    totalThreadsThatCanBeAssigned,
  ]);

  const isTotalGreaterThanAssignable =
    totalThreadsAssigned > totalThreadsThatCanBeAssigned;

  return (
    <Drawer show={show} onClose={onClose} format="default">
      <section className="flex flex-col h-full bg-slate-200 p-6 overflow-scroll">
        <form onSubmit={onSubmit}>
          <div className="flex flex-col justify-between gap-4">
            <Typography variant="h2">
              <FormattedMessage defaultMessage="Assign patients" />
            </Typography>
            <div
              className={clsx(
                formStep !== 'action' && 'hidden',
                'flex flex-col gap-4',
              )}
            >
              <div className="flex flex-col gap-4 bg-white p-4 shadow-md">
                <div className="flex flex-col justify-between gap-2">
                  <Typography variant="h3">
                    <FormattedMessage defaultMessage="What would you like to do?" />
                  </Typography>
                  <Radio
                    ref={register}
                    options={ACTION_OPTIONS}
                    className="flex flex-row gap-x-4"
                  />
                </div>
                {action === 'reassign' && (
                  <>
                    <Typography variant="h3">
                      <FormattedMessage defaultMessage="Reassign from" />
                    </Typography>
                    <Dropdown
                      name="reassigningFromUserId"
                      options={Object.entries(threadCountsByUserId).map(
                        ([userId, { fullName }]) => ({
                          label: fullName ?? '',
                          value: userId,
                        }),
                      )}
                      control={control}
                    />
                  </>
                )}
                <div className="flex flex-col justify-between gap-2">
                  <Typography variant="h3">
                    <FormattedMessage defaultMessage="Which patients would you like to assign?" />
                  </Typography>
                  <Radio
                    ref={register}
                    options={STATUSES_OPTIONS}
                    className="flex flex-row gap-x-4"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <Button variant="outline" onClick={onClose} type="button">
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                  variant="solid"
                  type="button"
                  onClick={(): void => {
                    setFormStep('assign');
                    Object.entries(balancedAssignSplit).forEach(
                      ([userId, count]) => {
                        setValue(`threadCounts.${userId}`, count);
                      },
                    );
                  }}
                  disabled={action === 'reassign' && !reassigningFromUserId}
                >
                  <FormattedMessage defaultMessage="Continue" />
                </Button>
              </div>
            </div>
            <div
              className={clsx(
                formStep !== 'assign' && 'hidden',
                'flex flex-col gap-4 text-slate-800',
              )}
            >
              <Typography variant="paragraph">
                <FormattedMessage defaultMessage="Automatic split is based on 'To Action' capacity. You can manually change split if needed. You can only assign equal to or less than the amount unassigned." />
              </Typography>
              <ul>
                <li>
                  <FormattedMessage
                    defaultMessage="<strong>Assignment type: </strong>{type}"
                    values={{
                      strong: (chunks) => <strong>{chunks}</strong>,
                      type: ACTION_OPTIONS.find((o) => o.value === action)
                        ?.label,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage="<strong>Status: </strong>{status}"
                    values={{
                      strong: (chunks) => <strong>{chunks}</strong>,
                      status: Object.entries(threadsThatCanBeAssignedByStatus)
                        .filter(([status]) => statuses.includes(status))
                        .map(
                          ([status, count]) =>
                            `${mapThreadStatusToReadableName(
                              status as ChatThreadStatus,
                            )} (${count})`,
                        )
                        .join(', '),
                    }}
                  />
                </li>
                {reassigningFromUserId && (
                  <li>
                    <FormattedMessage
                      defaultMessage="<strong>Reassigning from: </strong>{user}"
                      values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                        user: threadCountsByUserId[reassigningFromUserId]
                          ?.fullName,
                      }}
                    />
                  </li>
                )}
              </ul>
              <table className="table-auto shadow-md bg-white assign-table">
                <thead>
                  <tr>
                    <th colSpan={2}>&nbsp;</th>
                    <th colSpan={2}>
                      <FormattedMessage defaultMessage="Currently" />
                    </th>
                    <th>
                      <a
                        type="button"
                        onClick={(): void => {
                          Object.entries(balancedAssignSplit).forEach(
                            ([userId, count]) => {
                              setValue(`threadCounts.${userId}`, count);
                            },
                          );
                        }}
                        className="cursor-pointer underline text-blue-500"
                      >
                        <FormattedMessage defaultMessage="Reset split" />
                      </a>
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage defaultMessage="Coach" />
                    </th>
                    <th>
                      <FormattedMessage defaultMessage="Available" />
                    </th>
                    <th>
                      <FormattedMessage defaultMessage="To Action" />
                    </th>
                    <th>
                      <FormattedMessage defaultMessage="All" />
                    </th>
                    <th>
                      <FormattedMessage defaultMessage="Assigning" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(threadCountsByUserId)
                    .filter(([userId]) => userId !== reassigningFromUserId)
                    .map(([userId, userThreadCount], i) => (
                      <tr
                        key={userId}
                        className={clsx(
                          i % 2 === 0 ? 'bg-slate-100' : 'bg-white',
                        )}
                      >
                        <td>
                          <div className="mr-12 flex flex-row items-center gap-2">
                            {userThreadCount.avatarImageUrl && (
                              <Avatar
                                size="small"
                                name={userThreadCount.fullName}
                                patientId={userId}
                                avatarUrl={userThreadCount.avatarImageUrl}
                              />
                            )}
                            <Typography variant="paragraph">
                              {userThreadCount.fullName}
                            </Typography>
                          </div>
                        </td>
                        <td>
                          <Typography variant="paragraph">
                            <Toggle
                              name={`healthCoachAvailabilities.${userId}`}
                              checked={healthCoachAvailabilities[userId]}
                              onChange={(e): void => {
                                const makeAvailable = e.target.checked;
                                void setHealthCoachAvailability({
                                  variables: {
                                    userId,
                                    makeAvailable,
                                  },
                                  optimisticResponse: {
                                    setHealthCoachAvailability: {
                                      __typename:
                                        'SetHealthCoachAvailabilityPayload',
                                      user: {
                                        __typename: 'User',
                                        id: userId,
                                        isAvailable: makeAvailable,
                                      },
                                    },
                                  },
                                });
                                if (!makeAvailable) {
                                  setValue(`threadCounts.${userId}`, 0);
                                }
                              }}
                            />
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="paragraph">
                            {userThreadCount.counts.TO_ACTION}
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="paragraph">
                            {Object.values(userThreadCount.counts).reduce(
                              (acc, curr) => acc + curr,
                              0,
                            )}
                          </Typography>
                        </td>
                        <td>
                          <Input
                            ref={register({
                              valueAsNumber: true,
                              required: true,
                            })}
                            name={`threadCounts.${userId}`}
                            type="number"
                            min={0}
                            defaultValue={defaultThreadCounts[userId]}
                            disabled={!userThreadCount.isAvailable}
                          />
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td colSpan={5}>
                      <div className="flex flex-col justify-end items-end w-full">
                        <Typography variant="paragraph">
                          <FormattedMessage
                            defaultMessage="Total: {assignedThreads} (out of {totalThreads})"
                            values={{
                              assignedThreads: totalThreadsAssigned,
                              totalThreads: totalThreadsThatCanBeAssigned,
                            }}
                          />
                        </Typography>
                        {isTotalGreaterThanAssignable && (
                          <div className="flex flex-row justify-end items-center gap-1 w-full text-red-500">
                            <WarningDiamond />
                            <Typography variant="small-text">
                              <FormattedMessage
                                defaultMessage="Total exceeds the max. Lower total to ≤ {totalThreads}"
                                values={{
                                  totalThreads: totalThreadsThatCanBeAssigned,
                                }}
                              />
                            </Typography>
                          </div>
                        )}
                        {totalThreadsAssigned < 1 && (
                          <div className="flex flex-row justify-end items-center gap-1 w-full text-red-500">
                            <WarningDiamond />
                            <Typography variant="small-text">
                              <FormattedMessage defaultMessage="You must assign at least 1 patient" />
                            </Typography>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="flex flex-row gap-4">
                <Button variant="outline" onClick={onClose} type="button">
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                  variant="solid"
                  type="submit"
                  loading={
                    assignChatThreadsLoading || reassignChatThreadsLoading
                  }
                  disabled={
                    isTotalGreaterThanAssignable || totalThreadsAssigned < 1
                  }
                >
                  <FormattedMessage defaultMessage="Assign" />
                </Button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </Drawer>
  );
};
