import type { ChatThreadStatus, PatientsPageQuery, Maybe } from 'graphql/types';
import { useMemo } from 'react';

type ChatThreadStatusCount = {
  status?: ChatThreadStatus | null;
  count: number;
};

export const mapThreadStatusToReadableName = (
  status: Maybe<ChatThreadStatus>,
): string => {
  switch (true) {
    case status === 'TO_ACTION':
      return 'To Action';
    case status === 'AWAITING_PATIENT':
      return 'Awaiting Patient';
    case status === 'SCHEDULED_MESSAGE':
      return 'Scheduled Message';
    case status === 'NO_ACTION_REQUIRED':
      return 'No Action Required';
    case status === 'CLOSED':
      return 'Cancelled';
    default:
      return 'Unknown';
  }
};

export type ThreadCounts = {
  userCounts: ChatThreadStatusCount[];
  assignedCounts: ChatThreadStatusCount[];
  unassignedCounts: ChatThreadStatusCount[];
  allCounts: ChatThreadStatusCount[];
};

export const useChatThreadStatusCounts = (
  data: PatientsPageQuery | undefined,
): ThreadCounts => {
  const counts = useMemo(() => {
    const res: ThreadCounts = {
      userCounts: [
        { status: 'TO_ACTION', count: 0 },
        { status: 'AWAITING_PATIENT', count: 0 },
        { status: 'SCHEDULED_MESSAGE', count: 0 },
        { status: 'NO_ACTION_REQUIRED', count: 0 },
      ],
      assignedCounts: [
        { status: 'TO_ACTION', count: 0 },
        { status: 'AWAITING_PATIENT', count: 0 },
        { status: 'SCHEDULED_MESSAGE', count: 0 },
        { status: 'NO_ACTION_REQUIRED', count: 0 },
      ],
      unassignedCounts: [
        { status: 'TO_ACTION', count: 0 },
        { status: 'AWAITING_PATIENT', count: 0 },
        { status: 'SCHEDULED_MESSAGE', count: 0 },
        { status: 'NO_ACTION_REQUIRED', count: 0 },
        { status: 'CLOSED', count: 0 },
      ],
      allCounts: [],
    };

    if (!data) {
      return res;
    }

    const threadCounts = data.chatThreadStatusCountsByType;

    let assignedCounts: ChatThreadStatusCount[] = [];
    let userCounts: ChatThreadStatusCount[] = [];

    threadCounts?.assigned?.forEach((assignedCount) => {
      if (assignedCount.user?.id === data.profile?.id) {
        userCounts = assignedCount.counts ?? [];
      }
      if (assignedCount.counts) {
        assignedCounts = [...assignedCounts, ...assignedCount.counts];
      }
    });

    assignedCounts.forEach((count) => {
      const existingCount = res.assignedCounts.find(
        (c) => count.status === c.status,
      );
      if (existingCount) {
        existingCount.count += count.count;
      }
    });

    userCounts.forEach((count) => {
      const existingCount = res.userCounts.find(
        (c) => count.status === c.status,
      );
      if (existingCount) {
        existingCount.count += count.count;
      }
    });

    threadCounts?.unassigned?.forEach((count) => {
      const existingCount = res.unassignedCounts.find(
        (c) => count.status === c.status,
      );
      if (existingCount) {
        existingCount.count += count.count;
      }
    });

    res.allCounts = threadCounts?.all ?? [];

    return res;
  }, [data]);

  return counts;
};

export const getTotalCounts = (
  counts: ChatThreadStatusCount[] | null | undefined,
): number =>
  counts
    ? counts.reduce(
        (result, current) => result + (current.status ? current.count : 0),
        0,
      )
    : 0;

export const CHAT_THREAD_STATUSES: ChatThreadStatus[] = [
  'AWAITING_PATIENT',
  'CLOSED',
  'NO_ACTION_REQUIRED',
  'SCHEDULED_MESSAGE',
  'TO_ACTION',
];
